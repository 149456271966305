import React, { useState, useEffect } from "react";
import client from '../components/utils/client'

import Layout from "../components/Layout/layout";
import Banner from "./common/Banner";
import SideContent from "./trabalhe-conosco/SideContent";
import MainContent from "./trabalhe-conosco/MainContent";

const TrabalheConoscoPage = () => {
  const [allData, setAllData] = useState({});

  useEffect(() => {
    const handleGetData = () => {
      client()
        .get("content-group/17")
        .then(res => {
          let data = res.data || [];
          data.Contents.map(item => {
            if(item.Type === "image") {
                let obj = Object.assign(item);
                obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                return obj;
            }
          })
          setAllData(allData => ({
            ...allData,
            data,
            pageTitle: data.Contents.find(item => item.Key === "trabalhe-conosco-header-title").Value,
          }));
        })
        .catch(err => {
          console.log(err);
        })
    }

    const handleGetContactData = () => {
      client()
        .get("content-group/9")
        .then(res => {
          let contactData = res.data || [];
          contactData.Contents.map(item => {
            if(item.Type === "image") {
                let obj = Object.assign(item);
                obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                return obj;
            }
          })
          setAllData(allData => ({
            ...allData,
            contactData,            
          }));
        })
        .catch(err => {
          console.log(err);
        })
    }

    handleGetData();
    handleGetContactData();
  }, [])

  return (
    <Layout infoPage={{ page: `${allData.pageTitle}` }}>
      <div className="trabalhe-conosco">
        <Banner text={allData.pageTitle} />
        <div className="wrapper container-60 margin-vertical-100px">
          {allData.data && allData.contactData ? (
            <>
              <MainContent data={allData.data} />
              <SideContent data={allData.contactData} />
            </>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default TrabalheConoscoPage;