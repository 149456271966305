import React, { useState, useEffect } from 'react';

const MainContent = (props) => {
  const { data } = props;
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if(data.Contents) {
      let vagasList = data.Contents.filter(item => item.Key.includes("trabalhe-conosco-objeto"));
      let vagas = [];
      for(let i = 1; i <= (vagasList.length/3); i++) {
        let vaga = {
          title: vagasList.find(item => item.Key === `trabalhe-conosco-objeto-${i}-title`).Value,
          text: vagasList.find(item => item.Key === `trabalhe-conosco-objeto-${i}-text`).Value,
          image: vagasList.find(item => item.Key === `trabalhe-conosco-objeto-${i}-image`).Value,
        }
        vagas.push(vaga);
      }
      setAllData(allData => ({
        ...allData,
        main: {
          label: data.Contents.find(item => item.Key === "trabalhe-conosco-main-label").Value,
        },
        vagas,     
      }))
    }
  }, [data]);

  return (
    <div className="main-content">
      <div className="def__label__box">
        <div className="rectangle"></div>
        <p className="label">{allData.main ? allData.main.label : null}</p>
      </div>
      <section className="vagas">
        {allData.vagas ? allData.vagas.map((item, index) => (
          <div key={index} className="vaga">
            <span className="box-1">              
              <img src={item.image} alt="imagem vaga" className="vaga-image" />
              <button className="primary-button">SAIBA MAIS</button>
            </span>
            <span className="box-2">
              <h1 className="vaga-name" >{item.title}</h1>
              <p className="vaga-description" >{item.text}</p>
            </span>
          </div>
        )) : null}
        <button className="primary-button reverse" style={{padding: "10px 20px", letterSpacing: "2px", fontSize: ".85rem", alignSelf: "center"}}>VER MAIS</button>
      </section>
    </div>
  )
}

export default MainContent;