import React, { useState, useEffect } from 'react';

// icons
import fbLogo from "../../components/assets/img/header/menu/ic-fb-white.png";
import inLogo from "../../components/assets/img/header/menu/ic-in-white.png";
import wpLogo from "../../components/assets/img/header/menu/ic-wp-white.png";


const SideContent = (props) => {
  const { data } = props;
  const [allData, setAllData] = useState({});

  useEffect(() => {
    if(data.Contents) {
      setAllData(allData => ({
        ...allData,
        endereco: data.Contents.find(item => item.Key === "geral-contato-endereco").Value,
        telefone: data.Contents.find(item => item.Key === "geral-contato-telefone-1").Value,
        email: data.Contents.find(item => item.Key === "geral-contato-email-1").Value,
        facebook: data.Contents.find(item => item.Key === "geral-contato-facebook").Value,
        whatsapp: data.Contents.find(item => item.Key === "geral-contato-whatsapp").Value,
        linkedin: data.Contents.find(item => item.Key === "geral-contato-linkedin").Value,
      }))
    }
  }, [data])

  return (
    <div className="side-content">
      <div className="item">
        <h2>Onde estamos</h2>
        <p>{allData.endereco}</p>
      </div>
      <div className="item">
        <h2>Contato</h2>
        <ul>
          <li><a href="tel:+555433156990">{allData.telefone}</a></li>
          <li><a href="mailto:ezata@ezata.com.br">{allData.email}</a></li>
          </ul>
      </div>
      <div className="button-group">
        {allData.facebook ? (
          <a href={allData.facebook} target="_blank" rel="noreferrer" ><div><img className="icon" alt="Página Facebook" src={fbLogo} /></div></a>
        ) : null}
        {allData.linkedin ? (
          <a href={allData.linkedin} target="_blank" rel="noreferrer" ><div><img className="icon" alt="Perfil LinkedIn" src={inLogo} /></div></a>
        ) : null}
        {allData.whatsapp ? (
          <a href={allData.whatsapp} target="_blank" rel="noreferrer" ><div><img className="icon" alt="Grupo Whatsapp" src={wpLogo} /></div></a>
        ) : null}
      </div>
    </div>
  )
}

export default SideContent;